











































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { TimeTaskEntityModel, TimeTaskQueryModel } from '@common-src/entity-model/time-task-entity';
import TimeTaskService from '@common-src/service/time-task';
import { FormControlOptionModel } from '@common-src/model/form-control';
import { TimePlanRuleType } from '@common-src/model/enum';

@Component
export default class TimeTaskListComponent extends TableDialogFormComponent<TimeTaskEntityModel, TimeTaskQueryModel> {
    TimeTaskEntityModel = TimeTaskEntityModel;
    created() {
        this.initTable({
            service: TimeTaskService,
            queryModel: new TimeTaskQueryModel(),
            tableColumns: TimeTaskEntityModel.getTableColumns()
        });
        this.getList();
    }

    timePlanAddClick() {
        this.addClick(new TimeTaskEntityModel());
        this.$nextTick(() => {
            this.formChange({ key: 'cycleUnit' } as any);
        });
        // this.formChange({ key: 'cycleUnit' } as any);
    }

    formChange(model: FormControlOptionModel) {
        if (model.key === 'cycleUnit') {
            switch (this.formDialog.jtlForm.formModel.cycleUnit) {
                case TimePlanRuleType.DAY:
                    this.formDialog.jtlForm.formControlList[4].invisible = false;
                    this.formDialog.jtlForm.formControlList[5].invisible = true;
                    this.formDialog.jtlForm.formControlList[6].invisible = true;
                    this.formDialog.jtlForm.formControlList[7].invisible = true;
                    break;
                case TimePlanRuleType.WEEK:
                    this.formDialog.jtlForm.formControlList[4].invisible = true;
                    this.formDialog.jtlForm.formControlList[5].invisible = false;
                    this.formDialog.jtlForm.formControlList[6].invisible = true;
                    this.formDialog.jtlForm.formControlList[7].invisible = true;
                    break;
                case TimePlanRuleType.MONTH:
                    this.formDialog.jtlForm.formControlList[3].invisible = true;
                    this.formDialog.jtlForm.formControlList[4].invisible = true;
                    this.formDialog.jtlForm.formControlList[5].invisible = true;
                    this.formDialog.jtlForm.formControlList[6].invisible = false;
                    this.formDialog.jtlForm.formControlList[7].invisible = true;
                    break;
                case TimePlanRuleType.SEASON:
                    this.formDialog.jtlForm.formControlList[3].invisible = true;
                    this.formDialog.jtlForm.formControlList[4].invisible = true;
                    this.formDialog.jtlForm.formControlList[5].invisible = true;
                    this.formDialog.jtlForm.formControlList[6].invisible = true;
                    this.formDialog.jtlForm.formControlList[7].invisible = false;
            }
        }
    }
}

